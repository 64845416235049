<template>
  <PopUp :="popUp" @close="emit('done')">
    <template #title>
      <slot name="title" :="{ item }">
        <div
          class="Card__Title inline-flex gap-x-1 first-letter:uppercase"
          v-html="popUp.title"
        ></div>
      </slot>
    </template>

    <div class="mt-6 flex w-full flex-col justify-start text-left">
      <slot name="description" :="{ item }">
        <div
          class="text-sm text-gray-500 first-letter:uppercase"
          v-html="popUp.description"
        ></div>
      </slot>
      <div class="mt-4 flex w-full items-center justify-end gap-x-3">
        <Btn class="Btn--alt Btn--sm" type="button" @click="emit('done')">
          Cancel
        </Btn>
        <Btn
          class="Btn--primary Btn--sm w-40 capitalize"
          :loading="executing"
          @click="doAction"
        >
          {{ action }}
        </Btn>
      </div>
    </div>
  </PopUp>
</template>

<script setup lang="ts" generic="T">
export type ConfirmActionProps<T> = {
  action: string
  actionFn: (item: T, action: string) => unknown | Promise<unknown>
  item?: T
  name?: (item: T) => string
  description?: (item: T) => string
}
// defines
const { action, item, name, actionFn, description } =
  defineProps<ConfirmActionProps<T>>()
const emit = defineEmits(['done'])

// data
const executing = ref(false)

const popUp = reactive({
  open: false,
  title: '',
  description: ''
})

// methods
const doAction = async () => {
  if (!item) return
  executing.value = true
  await actionFn(item, action)
  emit('done')
  executing.value = false
}

// watchers
watchEffect(() => {
  if (item) {
    const itemName = name?.(item) ?? 'Untitled'
    popUp.open = true
    popUp.title = `${action} ${itemName}`
    popUp.description =
      description?.(item) ?? `Are you sure you want to ${action} ${itemName}?`
  } else {
    popUp.open = false
  }
})
</script>
